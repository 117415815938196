import * as React from "react"
import PropTypes from "prop-types"

import logo from "../images/PYT-FullLogoColorNoBkg.svg"
import Cta from "./cta"

function TempPage ({pageTitle}) {
  return (
    <div style={{display:"flex", flexDirection:"column"}}>
      <div className="mainContentSection">
        <h1 style={{marginTop:"1rem"}}>{pageTitle}</h1>
        <h4>This page is coming soon! Stay tuned!</h4>
      </div>
      <div style={{textAlign:"center"}}>
        <img className="tempImage" src={logo} alt="Logo" />
      </div>
      <Cta />
    </div>
  )
}

TempPage.defaultProps = {
  pageTitle: `Temporary Page`
}

TempPage.propTypes = {
  pageTitle: PropTypes.string
}

export default TempPage