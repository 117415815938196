import * as React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"

import TempPage from "../components/tempPage"

const BlogPage = () => (
  <Layout>
    <Seo title="Perfectly Yours Travel Co. Blog Page" />
    <TempPage pageTitle="Blog" />
  </Layout>
)

export default BlogPage
